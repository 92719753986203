<template>
    <div class="p-3">
        <div class="text-center">
            <el-radio-group v-model="status" @change="loadLandContracts(true)">
                <el-radio-button :label="1">新起草</el-radio-button>
                <el-radio-button :label="100">已退回</el-radio-button>
            </el-radio-group>
        </div>

        <el-card shadow="never" class="mt-3">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadLandContracts(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showLandContractDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="landContracts.data" class="mt-2">
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/land-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="partnerName" label="土地来源方" sortable />
                <el-table-column prop="contractArea" label="合同面积(亩)" sortable width="140" />
                <el-table-column prop="contractAmount" label="合同金额(元)" sortable width="140" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="lands" label="土地划分" sortable width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showLandsDialog(scope.row)">
                            {{scope.row.lands}} 块
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="140">
                    <template #default="scope">
                        <el-button type="text" @click="submitLandContract(scope.row.id)">提交</el-button>
                        <el-button type="text" @click="showLandContractDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deleteLandContract(scope.row.id)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="landContracts.totalRecords"
                :current-page="landContractsPagination.currentPage" :page-size="landContractsPagination.pageSize"
                class="mt-3" @current-change="landContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="landContractDialogVisible" title="土地合同" :close-on-click-modal="false" width="960px"
            top="0">
            <div style="margin: 0 -16px;">
                <el-form>
                    <div class="form-group-title">基本信息</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="编号" class="form-item-required">
                                    <el-input v-model="landContract.code" placeholder="编号" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="名称" class="form-item-required">
                                    <el-input v-model="landContract.name" placeholder="名称" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="土地行政区划" class="form-item-required">
                                    <el-input :value="landContract.regionName" readonly placeholder="土地行政区划">
                                        <template #append>
                                            <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="承包开始日期" class="form-item-required">
                                    <el-date-picker v-model="landContract.beginContractDate" placeholder="承包开始日期" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="承包结束日期" class="form-item-required">
                                    <el-date-picker v-model="landContract.endContractDate" placeholder="承包结束日期" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="土地流转方式" class="form-item-required">
                                    <el-select v-model="landContract.landTransferMethod" placeholder="土地流转方式">
                                        <el-option
                                            v-for="landTransferMethod in $store.state.dataDefinitions.landTransferMethods"
                                            :key="landTransferMethod.key" :value="landTransferMethod.key"
                                            :label="landTransferMethod.value" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="土地定价方式" class="form-item-required">
                                    <el-select v-model="landContract.landPriceMethod" placeholder="土地定价方式">
                                        <el-option
                                            v-for="landPriceMethod in $store.state.dataDefinitions.landPriceMethods"
                                            :key="landPriceMethod.key" :value="landPriceMethod.key"
                                            :label="landPriceMethod.value" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">土地来源方（甲方）</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="24">
                                <el-form-item label="土地来源方" class="form-item-required">
                                    <el-select v-model="landContract.partnerId" placeholder="土地来源方"
                                        @change="partnerChange">
                                        <el-option v-for="partner in partners" :key="partner.id" :value="partner.id"
                                            :label="partner.registeredUser.name" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="土地来源方合同签署人姓名" class="form-item-required">
                                    <el-input v-model="landContract.partnerSignerName" placeholder="土地来源方合同签署人姓名" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="土地来源方合同签署人手机" class="form-item-required">
                                    <el-input v-model="landContract.partnerSignerMobile" placeholder="土地来源方合同签署人手机" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="text-info">
                            注：已注册
                            <a href="https://www.qiyuesuo.com/" target="_blank">契约锁</a>
                            的网签公司，签署人必须是网签公司内部员工。
                        </div>
                    </div>

                    <div class="form-group-title">水发公司（乙方）</div>
                    <div class="form-group">
                        <el-row :gutter="8">
                            <el-col :span="24">
                                <el-form-item label="水发公司（乙方）" class="form-item-required">
                                    <el-select v-model="landContract.companyId" disabled placeholder="水发公司（乙方）">
                                        <el-option v-for="company in companies" :key="company.id" :value="company.id"
                                            :label="company.fullName" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="12">
                                <el-form-item label="水发合同签署人姓名" class="form-item-required">
                                    <el-input v-model="landContract.companySignerName" placeholder="水发合同签署人姓名" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="水发合同签署人手机" class="form-item-required">
                                    <el-input v-model="landContract.companySignerMobile" placeholder="水发合同签署人手机" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <div class="text-info">
                            注：已注册
                            <a href="https://www.qiyuesuo.com/" target="_blank">契约锁</a>
                            的网签公司，签署人必须是网签公司内部员工。
                        </div>
                    </div>

                    <div class="form-group-title">其他签署人</div>
                    <div class="form-group">
                        <div class="border rounded p-1">
                            <div v-for="otherSignatory in landContract.otherSignatories"
                                :key="otherSignatory.receiverMobile" class="row no-gutters border rounded mb-1">
                                <div class="col p-3">
                                    <div>
                                        {{otherSignatory.receiverName}}
                                        <span class="text-info">{{otherSignatory.receiverMobile}}</span>
                                    </div>
                                    <div v-if="otherSignatory.companyName" class="text-warning">
                                        {{otherSignatory.companyName}}
                                    </div>
                                    <div v-else class="text-info">个人</div>
                                </div>
                                <div class="col-auto">
                                    <el-button type="text" class="text-danger p-3"
                                        @click="removeOtherSignatory(otherSignatory)">
                                        移除
                                    </el-button>
                                </div>
                            </div>
                            <div>
                                <el-button @click="showAddOtherSignatoryDialog">添加</el-button>
                            </div>
                        </div>
                    </div>

                    <div class="form-group-title">内容</div>
                    <div class="form-group">
                        <el-tabs type="border-card">
                            <el-tab-pane label="确权地">
                                <el-row :gutter="8">
                                    <el-col :span="8">
                                        <el-form-item label="确权地面积（亩）">
                                            <el-input-number v-model="landContract.propertyArea"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="确权地单价（元/亩）">
                                            <el-input :value="landContractPropertyPrice" disabled />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="确权地金额（元）">
                                            <el-input-number v-model="landContract.propertyAmount"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="机动地">
                                <el-row :gutter="8">
                                    <el-col :span="8">
                                        <el-form-item label="机动地面积（亩）">
                                            <el-input-number v-model="landContract.reservedArea"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="机动地单价（元/亩）">
                                            <el-input :value="landContractReservedPrice" disabled />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="机动地金额（亩）">
                                            <el-input-number v-model="landContract.reservedAmount"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="国有土地">
                                <el-row :gutter="8">
                                    <el-col :span="8">
                                        <el-form-item label="国有土地面积（亩）">
                                            <el-input-number v-model="landContract.stateArea"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="国有土地单价（元/亩）">
                                            <el-input :value="landContractStatePrice" disabled />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="国有土地金额（亩）">
                                            <el-input-number v-model="landContract.stateAmount"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="基本农田">
                                <el-row :gutter="8">
                                    <el-col :span="8">
                                        <el-form-item label="基本农田面积（亩）">
                                            <el-input-number v-model="landContract.primaryArea"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="基本农田单价（元/亩）">
                                            <el-input :value="landContractPrimaryPrice" disabled />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="基本农田金额（亩）">
                                            <el-input-number v-model="landContract.primaryAmount"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="林业用地">
                                <el-row :gutter="8">
                                    <el-col :span="8">
                                        <el-form-item label="林业用地面积（亩）">
                                            <el-input-number v-model="landContract.forestryArea"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="林业用地单价（元/亩）">
                                            <el-input :value="landContractForestryPrice" disabled />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="林业用地金额（亩）">
                                            <el-input-number v-model="landContract.forestryAmount"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="村里集体土地">
                                <el-row :gutter="8">
                                    <el-col :span="8">
                                        <el-form-item label="村里集体土地面积（亩）">
                                            <el-input-number v-model="landContract.villageArea"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="村里集体土地单价（元/亩）">
                                            <el-input :value="landContractVillagePrice" disabled />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="村里集体土地金额（亩）">
                                            <el-input-number v-model="landContract.villageAmount"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="其他">
                                <el-row :gutter="8">
                                    <el-col :span="8">
                                        <el-form-item label="其他面积（亩）">
                                            <el-input-number v-model="landContract.otherArea"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="其他单价（元/亩）">
                                            <el-input :value="landContractOtherPrice" disabled />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="其他金额（亩）">
                                            <el-input-number v-model="landContract.otherAmount"
                                                controls-position="right" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                        </el-tabs>

                        <el-row :gutter="8">
                            <el-col :span="8">
                                <el-form-item label="合同面积（亩）">
                                    <el-input :value="landContractContractArea" disabled />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="合同单价（元/亩）">
                                    <el-input :value="landContractContractAveragePrice" disabled />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="合同金额（元）">
                                    <el-input :value="landContractContractAmount" disabled />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="8">
                                <el-form-item label="实测面积（亩）" class="form-item-required">
                                    <el-input-number v-model="landContract.measuredArea" controls-position="right" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="实耕面积（亩）" class="form-item-required">
                                    <el-input-number v-model="landContract.cultivatedArea" controls-position="right" />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="8">
                                <el-form-item label="口粮地面积（亩）" class="form-item-required">
                                    <el-input-number v-model="landContract.rationArea" controls-position="right" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="非口粮地面积（亩）">
                                    <el-input :value="landContractNonRationArea" disabled />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row :gutter="8">
                            <el-col :span="8">
                                <el-form-item label="水地面积（亩）" class="form-item-required">
                                    <el-input-number v-model="landContract.wetArea" controls-position="right" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="旱地面积（亩）">
                                    <el-input :value="landContractDryArea" disabled />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="form-group-title">附件</div>
                    <div class="form-group">
                        <div>
                            <div>权证附件</div>
                            <div class="border rounded mt-2 p-1">
                                <multi-file-uploader v-model="landContract.certificationAttachments" />
                            </div>
                        </div>

                        <div class="mt-3">
                            <div>待签约合同附件</div>
                            <div class="border rounded mt-2 p-1">
                                <multi-file-uploader v-model="landContract.draftContractAttachments"
                                    accept=".doc,.docx,.pdf,.jpg,.jpeg,.png" />
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
            <template #footer>
                <el-button type="primary" @click="saveLandContract">确定</el-button>
                <el-button @click="landContractDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="landsDialogVisible" title="最小单元土地划分" :close-on-click-modal="false" width="960px"
            @closed="loadLandContracts">
            <lands v-if="landsDialogVisible" :land-contract-id="landContract.id" />
        </el-dialog>

        <el-dialog :visible.sync="addOtherSignatoryDialogVisible" title="其他签署人" :close-on-click-modal="false"
            width="560px">
            <el-form>
                <el-form-item>
                    <el-radio-group v-model="otherSignatory.isCompany" class="stretch-radio-group"
                        @change="otherSignatory.companyName=''">
                        <el-radio-button :label="false">个人</el-radio-button>
                        <el-radio-button :label="true">公司</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="otherSignatory.isCompany" label="公司名称" class="form-item-required">
                    <el-input v-model="otherSignatory.companyName" placeholder="公司名称" />
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="签署人姓名" class="form-item-required">
                            <el-input v-model="otherSignatory.receiverName" placeholder="签署人姓名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="签署人电话" class="form-item-required">
                            <el-input v-model="otherSignatory.receiverMobile" placeholder="签署人电话" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="addOtherSignatory">确定</el-button>
                <el-button @click="addOtherSignatoryDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="landContract.regionId=$event.id;landContract.regionName=$event.name" />
    </div>
</template>

<script>
    import Lands from './components/Lands.vue';

    export default {
        data() {
            return {
                status: 1,
                key: null,
                partners: [],
                companies: [],
                landContracts: {},
                landContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                landContract: {},
                otherSignatory: {},
                landContractDialogVisible: false,
                selectRegionDialogVisible: false,
                landsDialogVisible: false,
                addOtherSignatoryDialogVisible: false,
            };
        },
        components: { Lands },
        computed: {
            landContractPropertyPrice() {
                if (this.landContract.propertyArea === 0) {
                    return 0;
                }
                return (this.landContract.propertyAmount / this.landContract.propertyArea).toFixed(2);
            },
            landContractReservedPrice() {
                if (this.landContract.reservedArea === 0) {
                    return 0;
                }
                return (this.landContract.reservedAmount / this.landContract.reservedArea).toFixed(2);
            },
            landContractStatePrice() {
                if (this.landContract.stateArea === 0) {
                    return 0;
                }
                return (this.landContract.stateAmount / this.landContract.stateArea).toFixed(2);
            },
            landContractPrimaryPrice() {
                if (this.landContract.primaryArea === 0) {
                    return 0;
                }
                return (this.landContract.primaryAmount / this.landContract.primaryArea).toFixed(2);
            },
            landContractForestryPrice() {
                if (this.landContract.forestryArea === 0) {
                    return 0;
                }
                return (this.landContract.forestryAmount / this.landContract.forestryArea).toFixed(2);
            },
            landContractVillagePrice() {
                if (this.landContract.villageArea === 0) {
                    return 0;
                }
                return (this.landContract.villageAmount / this.landContract.villageArea).toFixed(2);
            },
            landContractOtherPrice() {
                if (this.landContract.otherArea === 0) {
                    return 0;
                }
                return (this.landContract.otherAmount / this.landContract.otherArea).toFixed(2);
            },
            landContractContractArea() {
                return this.landContract.propertyArea + this.landContract.reservedArea + this.landContract.stateArea + this.landContract.primaryArea + this.landContract.forestryArea + this.landContract.villageArea + this.landContract.otherArea;
            },
            landContractContractAveragePrice() {
                if (this.landContractContractArea === 0) {
                    return 0;
                }
                return (this.landContractContractAmount / this.landContractContractArea).toFixed(2);
            },
            landContractContractAmount() {
                return this.landContract.propertyAmount + this.landContract.reservedAmount + this.landContract.stateAmount + this.landContract.primaryAmount + this.landContract.forestryAmount + this.landContract.villageAmount + this.landContract.otherAmount;
            },
            landContractNonRationArea() {
                return this.landContract.propertyArea - this.landContract.rationArea;
            },
            landContractDryArea() {
                return this.landContractContractArea - this.landContract.wetArea;
            },
        },
        methods: {
            async loadPartners() {
                let response = await this.$axios.get('/api/Partner/GetPartners', { params: { companyId: this.$store.state.companyId } });
                this.partners = response.data.data;
            },
            async loadCompanies() {
                let response = await this.$axios.get('/api/Organization/GetCompanies');
                this.companies = response.data;
            },
            async loadLandContracts(resetPage = false) {
                if (resetPage) {
                    this.landContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/LandContract/GetLandContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: this.status,
                        key: this.key,
                        pageIndex: this.landContractsPagination.currentPage - 1,
                        pageSize: this.landContractsPagination.pageSize,
                    }
                });
                this.landContracts = response.data;
            },
            landContractsPaginationCurrentChange(page) {
                this.landContractsPagination.currentPage = page;
                this.loadLandContracts();
            },
            showLandContractDialog(landContract) {
                if (landContract) {
                    this.landContract = JSON.parse(JSON.stringify(landContract));
                }
                else {
                    let company = this.$store.state.companies.filter(p => p.id == this.$store.state.companyId)[0];

                    this.landContract = {
                        partnerSignerName: '',
                        partnerSignerMobile: '',

                        companyId: this.$store.state.companyId,
                        companySignerName: company.companySignerName,
                        companySignerMobile: company.companySignerMobile,

                        otherSignatories: [],

                        propertyArea: 0,
                        propertyAmount: 0,
                        reservedArea: 0,
                        reservedAmount: 0,
                        stateArea: 0,
                        stateAmount: 0,
                        primaryArea: 0,
                        primaryAmount: 0,
                        forestryArea: 0,
                        forestryAmount: 0,
                        villageArea: 0,
                        villageAmount: 0,
                        otherArea: 0,
                        otherAmount: 0,
                        measuredArea: 0,
                        cultivatedArea: 0,
                        rationArea: 0,
                        wetArea: 0,

                        certificationAttachments: [],
                        draftContractAttachments: [],
                    };
                }
                this.landContractDialogVisible = true;
            },
            partnerChange(partnerId) {
                var partner = this.partners.filter(p => p.id == partnerId)[0];
                this.landContract.partnerSignerName = partner.contactName;
                this.landContract.partnerSignerMobile = partner.contactPhone;
            },
            showAddOtherSignatoryDialog() {
                this.otherSignatory = { isCompany: false, companyName: '' };
                this.addOtherSignatoryDialogVisible = true;
            },
            addOtherSignatory() {
                this.landContract.otherSignatories.push(this.otherSignatory);
                this.addOtherSignatoryDialogVisible = false;
            },
            removeOtherSignatory(otherSignatory) {
                let index = this.landContract.otherSignatories.indexOf(otherSignatory);
                this.landContract.otherSignatories.splice(index, 1);
            },
            async saveLandContract() {
                if (this.landContract.id == undefined) {
                    await this.$axios.post('/api/LandContract/AddLandContract', {
                        code: this.landContract.code,
                        name: this.landContract.name,
                        regionId: this.landContract.regionId,
                        beginContractDate: this.$moment(this.landContract.beginContractDate).format('YYYY/M/D'),
                        endContractDate: this.$moment(this.landContract.endContractDate).format('YYYY/M/D'),
                        landTransferMethod: this.landContract.landTransferMethod,
                        landPriceMethod: this.landContract.landPriceMethod,

                        partnerId: this.landContract.partnerId,
                        partnerSignerName: this.landContract.partnerSignerName,
                        partnerSignerMobile: this.landContract.partnerSignerMobile,

                        companyId: this.landContract.companyId,
                        companySignerName: this.landContract.companySignerName,
                        companySignerMobile: this.landContract.companySignerMobile,

                        otherSignatories: this.landContract.otherSignatories,

                        propertyArea: this.landContract.propertyArea,
                        propertyAmount: this.landContract.propertyAmount,
                        reservedArea: this.landContract.reservedArea,
                        reservedAmount: this.landContract.reservedAmount,
                        stateArea: this.landContract.stateArea,
                        stateAmount: this.landContract.stateAmount,
                        primaryArea: this.landContract.primaryArea,
                        primaryAmount: this.landContract.primaryAmount,
                        forestryArea: this.landContract.forestryArea,
                        forestryAmount: this.landContract.forestryAmount,
                        villageArea: this.landContract.villageArea,
                        villageAmount: this.landContract.villageAmount,
                        otherArea: this.landContract.otherArea,
                        otherAmount: this.landContract.otherAmount,
                        measuredArea: this.landContract.measuredArea,
                        cultivatedArea: this.landContract.cultivatedArea,
                        rationArea: this.landContract.rationArea,
                        wetArea: this.landContract.wetArea,

                        certificationAttachments: this.landContract.certificationAttachments,
                        draftContractAttachments: this.landContract.draftContractAttachments,
                    });
                    this.landContractDialogVisible = false;
                    this.loadLandContracts();
                }
                else {
                    await this.$axios.post('/api/LandContract/UpdateLandContract', {
                        id: this.landContract.id,
                        code: this.landContract.code,
                        name: this.landContract.name,
                        regionId: this.landContract.regionId,
                        beginContractDate: this.$moment(this.landContract.beginContractDate).format('YYYY/M/D'),
                        endContractDate: this.$moment(this.landContract.endContractDate).format('YYYY/M/D'),
                        landTransferMethod: this.landContract.landTransferMethod,
                        landPriceMethod: this.landContract.landPriceMethod,

                        partnerId: this.landContract.partnerId,
                        partnerSignerName: this.landContract.partnerSignerName,
                        partnerSignerMobile: this.landContract.partnerSignerMobile,

                        companyId: this.landContract.companyId,
                        companySignerName: this.landContract.companySignerName,
                        companySignerMobile: this.landContract.companySignerMobile,

                        otherSignatories: this.landContract.otherSignatories,

                        propertyArea: this.landContract.propertyArea,
                        propertyAmount: this.landContract.propertyAmount,
                        reservedArea: this.landContract.reservedArea,
                        reservedAmount: this.landContract.reservedAmount,
                        stateArea: this.landContract.stateArea,
                        stateAmount: this.landContract.stateAmount,
                        primaryArea: this.landContract.primaryArea,
                        primaryAmount: this.landContract.primaryAmount,
                        forestryArea: this.landContract.forestryArea,
                        forestryAmount: this.landContract.forestryAmount,
                        villageArea: this.landContract.villageArea,
                        villageAmount: this.landContract.villageAmount,
                        otherArea: this.landContract.otherArea,
                        otherAmount: this.landContract.otherAmount,
                        measuredArea: this.landContract.measuredArea,
                        cultivatedArea: this.landContract.cultivatedArea,
                        rationArea: this.landContract.rationArea,
                        wetArea: this.landContract.wetArea,

                        certificationAttachments: this.landContract.certificationAttachments,
                        draftContractAttachments: this.landContract.draftContractAttachments,
                    });
                    this.landContractDialogVisible = false;
                    this.loadLandContracts();
                }
            },
            async deleteLandContract(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/LandContract/DeleteLandContract', { id: id });
                this.loadLandContracts();
            },
            async submitLandContract(id) {
                if (!confirm('确定要提交吗？')) {
                    return;
                }

                await this.$axios.post('/api/LandContract/SubmitLandContract', { id: id });
                this.loadLandContracts();
            },
            showLandsDialog(landContract) {
                this.landContract = JSON.parse(JSON.stringify(landContract));
                this.landsDialogVisible = true;
            },
        },
        created() {
            this.loadPartners();
            this.loadCompanies();
            this.loadLandContracts();
        },
    };
</script>

<style>
    .form-group-title {
        background-color: #f2f6fc;
        padding: 1em 3em;
        border-left: 4px solid #409eff;
        font-weight: bold;
    }

    .form-group {
        padding: 1em 3em;
    }
</style>